// extracted by mini-css-extract-plugin
export var button = "shifts-module--button--bH6LR";
export var container = "shifts-module--container--uYy8X";
export var contentSection = "shifts-module--content-section--v5PCb";
export var contentSectionSlide = "shifts-module--content-section-slide--1ctL4";
export var contentSectionSlideLabel = "shifts-module--content-section-slide-label--oP4zX";
export var contentSectionSlideTag = "shifts-module--content-section-slide-tag--A1aNr";
export var contentSectionSlideTags = "shifts-module--content-section-slide-tags--uNavi";
export var contentSectionSlideTitle = "shifts-module--content-section-slide-title--0VAlL";
export var contentSectionSlideTitles = "shifts-module--content-section-slide-titles--bcVTk";
export var link = "shifts-module--link--cwXL9";
export var section = "shifts-module--section--Wj-nm";
export var signupSection = "shifts-module--signup-section--Zt8N4";
export var signupSectionBody = "shifts-module--signup-section-body--JPy+e";
export var signupSectionForm = "shifts-module--signup-section-form--qMKx5";
export var signupSectionName = "shifts-module--signup-section-name--GYQSA";
export var signupSectionPodcastIcons = "shifts-module--signup-section-podcast-icons--UuFvD";
export var signupSectionPodcastLabel = "shifts-module--signup-section-podcast-label--O8O0j";
export var signupSectionTitle = "shifts-module--signup-section-title--fo3XB";
export var spacer = "shifts-module--spacer--NBq1w";
export var spacerMiddle = "shifts-module--spacer-middle--5oPA+";