import React from 'react';
import { Banner } from './public_home/banner';
import { PartnerLogos, TestimonialCarousel } from './public_home/testimonials';
import { Shifts } from './public_home/shifts';
import { SignupRibbon } from './public_home/signup_ribbon';
import { Brief } from './public_home/brief';
import { BriefCta } from './public_home/brief_cta';
import { Graph } from './public_home/graph';
import { Membership } from './public_home/membership';


const PublicHome = ({ location, primary, rest, userFields }) => {

    return (
        <div
          style={{
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `center`
          }} >
          
          { Banner({ location }) }
          { PartnerLogos() }
          { TestimonialCarousel() }
          { Shifts({ primary, rest }) }
          { SignupRibbon() }
          { Brief({ primary, rest }) }
          { BriefCta({ primary, rest }) }
          { Graph({ data: userFields, primary, rest }) }
          { Membership({ userFields }) }

        </div>
    );
};
export default PublicHome;
