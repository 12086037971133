import { Link } from "gatsby";
import React from 'react';
import { colors } from '../colors';
import { joinButton } from '../join_button';
import { mostRecentPublicSpecialEdition } from './data';
import * as styles from './brief_cta.module.css';



//
// Brief CTA component

export const BriefCta = ({ primary, rest }) => {

  const buttonProps = {
    narrow: {
      style: {
        padding: `14px 60px`,
        fontWeight: `300` }},
    wide: {
      style: {
        padding: `15px`,
        fontWeight: `300` }}};

  const sampleBrief = mostRecentPublicSpecialEdition({ primary, rest });
  
  return (
      <div className={ styles.container } >

      <div className={ styles.title } >
      MEMBER-ONLY BRIEFS
    </div>

      <div className={ styles.body } >
      Deep research on the most important far-reaching market shifts in technology and business.
      </div>

      <div className={ `${styles.onlyNarrow} ${styles.button}` } >
      { joinButton(buttonProps.narrow) }
    </div>

      <div className={ `${styles.onlyWide} ${styles.button}` } >
      { joinButton(buttonProps.wide) }
    </div>

      <div
    className={ styles.links }
    style={{ color: colors.green }} >

      <Link
    className={ styles.link }
    style={{ color: colors.green }}
    to={ sampleBrief.slug_path + '#headline' } >
      Sample Brief
    </Link>

      <Link
    className={ styles.link }
    style={{ color: colors.green }}
    to={ '/search/?query=Brief' } >
      Browse Briefs
    </Link>

    </div>

    </div>
  );
};
