import React from 'react';
import { dissoc } from '../../utils/functional';
import { heading, render } from '../brief/body';
import { readingTimeSection } from '../brief/reading_time';
import { lineSection, linkContainerStyle } from '../brief/render/callout';
import { mostRecentSpecialEdition } from './data';
import * as styles from './brief.module.css';


//
// body section

const Body = ({ brief }) => {
  
  const body = JSON.parse(brief.body_preview);
  const content = body
        .slice(1)             // render needs body[0] in acc
        .concat([undefined])  // render operates on the last/previous item
        .reduce(render, { last: body[0], results: [] })
        .results;

  return (
      <div className={ styles.bodySection } >

    { heading({ data: brief, includeAnchor: false }) }

      <div style={{ margin: `10px 0 27px 0` }} >
      { readingTimeSection(brief) }
    </div>

      <div>{ content }</div>

    </div>
  );
};


//
// callout section

const Callout = ({ brief }) => {

  const cs = JSON.parse(brief.body_callouts);
  const s = dissoc( linkContainerStyle, 'display' );

  return (
      <div
    className={ styles.calloutSection }
    style={ s } >

    { lineSection() }

      <div style={{ flex: `0 0 40px` }} />

    { cs[0] }

    </div>
  );
};


//
// Brief component

export const Brief = ({ primary, rest }) => {

  const brief = mostRecentSpecialEdition({ primary, rest });

  return (
      <div className={ styles.container } >

    { Body({ brief }) }
      <div className={ styles.spacerMiddle } />
      { Callout({ brief }) }

    </div>
  );
};
