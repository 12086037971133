import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { jsonToJs, buildAnchors } from '../components/brief/data';
import BriefLayout from '../components/brief_layout';
import PublicHome from '../components/public_home';
import { refreshSessionId } from '../utils/session_id';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: "Homepage" });


const originType = process.env.ORIGIN_TYPE;
const shouldShowPublicHome = originType === 'public';



const Index = ({ data, location }) => {

  const briefs = jsonToJs(data.allInternalBriefsJson);
  const primary = briefs[0];
  const rest = briefs.slice(1);
  const userFields = data.allUserFieldsJson.edges[0].node;

  useEffect(() => { refreshSessionId(); }, []);

  return (
      <Layout
    anchors={ buildAnchors(briefs[0]) }
    location={ location }
    includeJumpLink={ true }
    removeHorizontalPadding={ shouldShowPublicHome } >

    { shouldShowPublicHome ?
      PublicHome({ location, primary, rest, userFields })
      : BriefLayout({ location, primary, rest, userFields }) }
    
    </Layout>
  );
};


export default Index;



export const pageQuery = graphql`
  query {
    allInternalBriefsJson {
      edges {
        node {
          body
          body_callouts
          body_partial
          body_preview
          body_quotes
          brief_id
          created_epoch_ts
          disclosure
          headline
          headline_pre
          podcast { id type }
          public_until
          published_date_str
          published_epoch_ts
          published_date_word_str
          shift_titles
          slug_path
          social
          summary
          tags
          type
          word_count
        }
      }
    }

    allUserFieldsJson {
      edges {
        node {
          market_events
          market_sectors
          signals_selected
          shifts_researched
          quick_hits
        }
      }
    }
  }
`;
