import { Link } from "gatsby";
import React from 'react';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../banner/carousel.css';

import { filter, lastVal, threadLast, sort } from '../../utils/functional';
import { tag } from '../brief/headline';
import { joinButton } from '../join_button';
import * as styles from './shifts.module.css';
import { colors } from '../colors';


const mostRecentPublicShifts = ({ primary, rest }) => {
    return threadLast( [primary].concat(rest),
                       [filter, (b) => b.type === 'SHIFTS'],
                       [filter, (b) => b.body !== null && b.body !== undefined],
                       [sort, (a,b) => Number(a.published_epoch_ts) - Number(b.published_epoch_ts)],
                       lastVal);  // most recent
};



//
// signup section

const SignupSection = ({ primary, rest }) => {

    const shifts = mostRecentPublicShifts({ primary, rest });
    
    return (

        <div className={ `${styles.section} ${styles.signupSection}` } >

            <div className={ styles.signupSectionName } >
                WEEKLY 3 SHIFTS EDITION
            </div>
            
            <div className={ styles.signupSectionTitle } >
                The top 3 market shifts to know each week
            </div>

            <div className={ styles.signupSectionBody } >
                Packed with insights and designed to be read in 10 minutes.
            </div>

            <div className={ styles.button } >
                {
                    joinButton({
                        content: "Subscribe Now",
                        style: { padding: '14px 27px' }
                    })
                }
            </div>

            <Link
                className={ styles.link }
                style={{ color: colors.white }}
                to={ shifts.slug_path + '#headline' } >
                Sample 3 Shifts Edition
            </Link>
            
        </div>
    );
};



//
// content section

const ContentSlideTag = (str) => {
    const newTag = tag(null, {});
    return (
        <div
          className={ styles.contentSectionSlideTag }
          key={ Math.random() } >
          { newTag(str) }
        </div>
    );
};

const ContentSlideTitle = (url) => (str) => {
    return (
        <Link
          className={ styles.contentSectionSlideTitle }
          key={ Math.random() }
          style={{ color: colors.whiteOff }}
          to={ url } >
          { str }
        </Link>
    );
};

const ContentSlide = (brief) => {

    const { shift_titles, slug_path, published_date_word_str, tags } = brief;
    const url = slug_path + "#headline";
    
    return (
        <div
          className={ styles.contentSectionSlide }
          key={ Math.random() } >
          
          <Link
            className={ styles.contentSectionSlideLabel }
            style={{ color: colors.whiteOff }}
            to={ url } >
            WEEK OF { published_date_word_str.toUpperCase() }
          </Link>

          <div
            className={ styles.contentSectionSlideTags }
            style={{ color: colors.orange }} >
            { tags.visible.map(ContentSlideTag) }
          </div>

          <div className={ styles.contentSectionSlideTitles } >
            { shift_titles.map(ContentSlideTitle(url)) }
          </div>
          
        </div>
    );
};

const getConfigurableProps = () => ({
    showArrows: false,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: true,
    thumbWidth: 100,
    selectedItem: 0,
    interval: 5000,
    transitionTime: 500,
    swipeScrollTolerance: 5,
});

const ContentSection = ({ primary, rest }) => {

    const rss = filter((b) => b.type === 'SHIFTS',  [primary].concat(rest) );
    const ss = rss.slice(0, 3);

    return (
        <div className={ `${styles.section} ${styles.contentSection}` } >

          <Carousel {...getConfigurableProps()} >
            { ss.map(ContentSlide) }
          </Carousel>
          
        </div>
    );
};


//
// Shifts component

export const Shifts = ({ primary, rest }) => {
    return (
        <div
            className={ styles.container }
            style={{
                backgroundColor: colors.darkBackgroundGrey,
                color: colors.whiteOff
            }} >

            <div className={ styles.spacer } />
            
            <SignupSection primary={ primary } rest={ rest } />
            
            <div className={ styles.spacerMiddle } />
            
            <ContentSection primary={ primary } rest={ rest } />
            
            <div className={ styles.spacer } />
            
        </div>
    );
};
