import { filter, join, lastVal,
         map, partition,
         reverse, sort,
         threadLast } from '../../utils/functional';


export const addCommasToNumber = (n) => {
    const ns = typeof n === 'string' ? n : String(n);
    return threadLast( ns,
                       reverse,
                       (s) => s.split(''),
                       [partition, 3],
                       [map, (g) => join('', g)],
                       [join, ','],
                       reverse);

};

export const numberOfBriefs = ({ primary, rest }) => {
    const contents = [primary].concat(rest);
    const isBrief = (b) => b.type === 'BRIEF';
    return contents.filter(isBrief).length;
};

export const numberOfShifts = ({ primary, rest }) => {
    const contents = [primary].concat(rest);
    const isShifts = (b) => b.type === 'SHIFTS';
    const n = contents.filter(isShifts).length;
    return n * 3; // mult by 3 b/c there are 3 Shifts in each Shifts content
};

export const numberOfEditions = ({ primary, rest }) => {
    const contents = [primary].concat(rest);
    return contents.length;
};

export const numberOfShiftsAndBriefs = ({ primary, rest }) => {
    return numberOfShifts({ primary, rest }) + numberOfBriefs({ primary, rest });
};



export const mostRecentSpecialEdition = ({ primary, rest }) => {

    return threadLast( [primary].concat(rest),
                       [filter, (b) => b.type === 'SPECIAL'],
                       [filter, (b) => b.body_preview !== null && b.body_preview !== undefined],
                       [sort, (a,b) => Number(a.published_epoch_ts) - Number(b.published_epoch_ts)],
                       lastVal);
};

export const mostRecentPublicSpecialEdition = ({ primary, rest }) => {

    return threadLast( [primary].concat(rest),
                       [filter, (b) => b.type === 'SPECIAL'],
                       [filter, (b) => b.body !== null && b.body !== undefined],
                       [sort, (a,b) => Number(a.published_epoch_ts) - Number(b.published_epoch_ts)],
                       lastVal);
};
