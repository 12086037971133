// extracted by mini-css-extract-plugin
export var container = "graph-module--container--XSRyX";
export var contentSection = "graph-module--content-section--4Pwnh";
export var contentSectionBody = "graph-module--content-section-body--JcZWF";
export var contentSectionLink = "graph-module--content-section-link--IVlGm";
export var contentSectionTitle = "graph-module--content-section-title--8d60Q";
export var dataSection = "graph-module--data-section--uyWV0";
export var dataSectionGraph = "graph-module--data-section-graph--CjDn7";
export var dataSectionGraphSpacer = "graph-module--data-section-graph-spacer--YMFgo";
export var dataSectionGraphSpacerMiddle = "graph-module--data-section-graph-spacer-middle--ohBJs";
export var dataSectionImage = "graph-module--data-section-image--SPybu";
export var dataSectionNumbers = "graph-module--data-section-numbers--UIQAv";
export var dataSectionNumbersItem = "graph-module--data-section-numbers-item--rpPcO";
export var dataSectionNumbersItemNumber = "graph-module--data-section-numbers-item-number--IpLLT";
export var dataSectionNumbersItemText = "graph-module--data-section-numbers-item-text--0zutF";
export var dataSectionSide = "graph-module--data-section-side--fJTr7";
export var dataSectionSideNumber = "graph-module--data-section-side-number--Fka74";
export var dataSectionSideText = "graph-module--data-section-side-text--s5d1v";
export var dataSectionSideTextNumber = "graph-module--data-section-side-text-number--MaOf-";
export var flexContainer = "graph-module--flex-container--27cav";
export var spacerMiddle = "graph-module--spacerMiddle--Vxqx7";