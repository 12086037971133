// extracted by mini-css-extract-plugin
export var body = "banner-module--body--Bc8dg";
export var bodyText = "banner-module--body-text--7bwKe";
export var container = "banner-module--container--Y8sZi";
export var endSpacer = "banner-module--end-spacer--xUaqj";
export var headlineText = "banner-module--headline-text--uYw46";
export var image = "banner-module--image--8Vgaz";
export var imageContainer = "banner-module--image-container--nqfbD";
export var joinContainer = "banner-module--join-container--KCPsY";
export var middleSpacer = "banner-module--middle-spacer--81-eD";
export var newsletterSignupContainer = "banner-module--newsletter-signup-container--sFrAG";
export var newsletterSignupForm = "banner-module--newsletter-signup-form--+T+vD";
export var newsletterSignupTitle = "banner-module--newsletter-signup-title--fOPp1";
export var newsletterSignupTitleTextNarrow = "banner-module--newsletter-signup-title-text-narrow--+SDsi";
export var newsletterSignupTitleTextWide = "banner-module--newsletter-signup-title-text-wide--BC+ao";
export var startSpacer = "banner-module--start-spacer--DbnzI";
export var textContainer = "banner-module--text-container--psUpZ";