import { Link } from 'gatsby';
import React from 'react';
import { colors } from '../colors';
import { addCommasToNumber, numberOfEditions } from './data';
import * as styles from './graph.module.css';



const ContentSection = () => {
    return (
        <div className={ styles.contentSection }>

          <div className={ styles.contentSectionTitle } >
            How we work
          </div>

          <div className={ styles.contentSectionBody } >
            We combine aggregation & automation technologies with consulting-style sprints to build a clear picture of what’s changing and what lies ahead.
          </div>

          <Link
            className={ styles.contentSectionLink }
            style={{ color: colors.green }}
            to='/about' >
            About Us
          </Link>
          
        </div>
    );
};


//
// data section

const dataItems = ({ data, primary, rest }) => {
    return [
        { number: '',
          text: '' },
        { number: data.signals_selected,
          text: 'Curated signals' },
        { number: data.quick_hits,
          text: 'Prioritized signals' },
        { number: numberOfEditions({ primary, rest }),
          text: 'Editions' },
        { number: data.shifts_researched,
          text: 'Shifts covered' }
    ];
};

const DataSectionItem = ({ number, text }) => {
    return (
        <div className={ styles.dataSectionNumbersItem } >
          
          <div className={ styles.dataSectionNumbersItemNumber } >
            { addCommasToNumber(number) }
          </div>

          <div
            className={ styles.dataSectionNumbersItemText }
            dangerouslySetInnerHTML={{ __html: text }} />
          
        </div>
    );
};


const DataSection = ({ data, primary, rest }) => {
    return (
        <div className={ styles.dataSection } >

          <div className={ styles.dataSectionGraph } >
            
            <div className={ styles.dataSectionSide }>

              <div className={ styles.dataSectionSideNumber } >
                { addCommasToNumber( data.market_events ) }
              </div>

              <div className={ styles.dataSectionSideText } >

                market events captured within

                <span className={ styles.dataSectionSideTextNumber } >
                  { addCommasToNumber( data.market_sectors ) }+
                </span>

                sectors
                
              </div>
              
            </div>

            <div className={ styles.dataSectionGraphSpacerMiddle } />

            <div className={ styles.dataSectionImage } />

            <div className={ styles.dataSectionGraphSpacer } />

          </div>

          
          <div className={ styles.dataSectionNumbers } >

            { dataItems({ data, primary, rest }).map(DataSectionItem) }
            
          </div>
          

        </div>
    );
};


//
// graph component

export const Graph = ({ data, primary, rest }) => {

    return (
        <div
          className={ styles.container }
          style={{ background: colors.lightestGrey }} >

          <div className={ styles.flexContainer } >

            { ContentSection() }

            <div className={ styles.spacerMiddle } />

            { DataSection({ data, primary, rest }) }
            
          </div>
          
        </div>
    );
};
