import React from 'react';
import { colors } from '../colors';
import { joinButton } from '../join_button';
import NewsletterSignup from '../newsletter_signup';
import * as styles from './banner.module.css';


const newsletterSignupSection = () => {
    return (
        <div className={ styles.newsletterSignupContainer } >

          <div
            className={ styles.newsletterSignupTitle } >

            <div className= { styles.newsletterSignupTitleTextNarrow } >
              Or start for free
            </div>
            <div className= { styles.newsletterSignupTitleTextWide } >
              Start for free
            </div>
          </div>

          <div className={ styles.newsletterSignupForm } >

            <NewsletterSignup
              fontSize='14px'
              height='40px'
              padding='4px 0 0'
              placeholder='Enter your email'
            />

          </div>

        </div>
    );
};

const textSection = () => {
    return (
        <div className={ styles.textContainer } >

          <div className={ styles.headlineText } >
            The source for far-reaching market shifts and what they mean
          </div>

          <div className={ styles.bodyText } >
            Get consulting-quality research without the project. Deep, clear briefs that help you make better and faster decisions.
          </div>

          <div className={ styles.joinContainer } >
            { joinButton({ style: { padding: `8px 27px`, fontSize: `14px` }}) }
          </div>

          { newsletterSignupSection() }

        </div>
    );
};




//
// banner

export const Banner = ({ location }) => {
    return (
        <div
          className={ styles.container }
          style={{ background: colors.lightestGrey }} >

          <div className={ styles.startSpacer } />

          <div
            className={ styles.body } >
            { textSection() }
          </div>

          <div
            className={ styles.middleSpacer } />

          <div
            className={ styles.imageContainer } >
            <div
              className={ styles.image }
              alt={ `6Pages mobile` } />
          </div>

          <div className={ styles.endSpacer } />

        </div>
    );
};
